import React, { FC } from "react"
import { navigate, PageProps } from "gatsby"
import T from "@mui/material/Typography"
import { QuestionPageContext } from "../interfaces/PageContext"
import Button from "../components/design-system/Button"
import ButtonWrapper from "../components/ButtonWrapper"
import SEO from "../components/SEO"
import QuestionList from "../components/QuestionList"
import useQuestionAnswers from "../hooks/useQuestionAnswers"
import { isPageVisible, isSectionVisible } from "../utility/visibility"
import { useUser } from "../providers/UserProvider"

const QuestionPage: FC<PageProps<never, QuestionPageContext>> = ({
  pageContext,
}) => {
  const { section, subsection, page, previousPages, nextPages, questions } =
    pageContext

  const answers = useQuestionAnswers()
  const { profile, activity } = useUser()
  const { options } = questions

  if (activity !== undefined && activity.confirmedUCASDetails === undefined) {
    navigate("/application", { replace: true })
    return <></>
  }

  if (activity?.submissionTimestamp) {
    // Navigate away to the question's review page if the application has been submitted.
    navigate(section.path + "/review", { replace: true })
    return <></>
  }

  const visibleNextPages = nextPages.filter(thisPage =>
    isPageVisible(
      thisPage.page,
      thisPage.subsection,
      thisPage.section,
      answers,
      profile
    )
  )

  const sectionVisible = isSectionVisible(section, answers, profile)

  // Go to next page if it's in the same section or the current section isn't visible.
  // Otherwise, go to section review screen.
  const nextPagePath =
    visibleNextPages.length &&
    (visibleNextPages[0].section.id === section.id || !sectionVisible)
      ? visibleNextPages[0].page.path
      : section.path + "/review"

  if (answers && !isPageVisible(page, subsection, section, answers, profile)) {
    if (typeof window !== "undefined") navigate(nextPagePath, { replace: true })

    // Don't briefly show the page we're skipping.
    // Should probably be replaced with a proper loading state...
    return <></>
  }

  const visiblePreviousPages = previousPages
    .filter(thisPage =>
      isPageVisible(
        thisPage.page,
        thisPage.subsection,
        thisPage.section,
        answers,
        profile
      )
    )
    .reverse()

  // Go to previous page if it's in the same section.
  // Otherwise if there are previous visible questions go to the previous section's review screen.
  // Otherwise go home.
  const previousPagePath = visiblePreviousPages.length
    ? visiblePreviousPages[0].section.id === section.id
      ? visiblePreviousPages[0].page.path
      : visiblePreviousPages[0].section.path + "/review"
    : "/application"

  return (
    <>
      <SEO title={`${section.title} > ${page.title}`} />
      <T variant="h3" component="h1">
        {section.title}
      </T>
      <QuestionList questions={page.questions} options={options} />
      <ButtonWrapper
        display="flex"
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "flex-start",
          rowGap: 1,
          columnGap: 2,
        }}
      >
        <Button
          color="secondary"
          role="link"
          onClick={() => navigate(previousPagePath)}
          data-test-button="back"
        >
          Previous
        </Button>
        <Button
          role="link"
          color="primary"
          onClick={() => navigate(nextPagePath)}
          data-test-button="continue"
        >
          {nextPagePath === section.path + "/review"
            ? "Review your answers"
            : "Next"}
        </Button>
      </ButtonWrapper>
    </>
  )
}

export default QuestionPage
