import {
  Question as QuestionType,
  Statement as StatementType,
  Schema,
} from "../data/question-schema"
import Box from "@mui/material/Box"
import Statement from "./Statement"
import Question from "./questions"
import React, { FC } from "react"

export interface QuestionListProps {
  questions: (QuestionType | StatementType)[]
  options: Schema["options"]
}

const QuestionList: FC<QuestionListProps> = ({ questions, options }) => {
  return (
    <Box sx={{ m: 0, p: 0 }}>
      {questions.map(question =>
        question.type === "statement" ? (
          <Statement key={question.id} data={question} options={options} />
        ) : (
          <Question.Input key={question.id} data={question} options={options} />
        )
      )}
    </Box>
  )
}

export default QuestionList
